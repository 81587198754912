/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Branch } from './Branch';
import {
    BranchFromJSON,
    BranchFromJSONTyped,
    BranchToJSON,
    BranchToJSONTyped,
} from './Branch';

/**
 * 
 * @export
 * @interface BranchWithAmountAndQuantity
 */
export interface BranchWithAmountAndQuantity {
    /**
     * 
     * @type {Branch}
     * @memberof BranchWithAmountAndQuantity
     */
    branch: Branch;
    /**
     * 
     * @type {number}
     * @memberof BranchWithAmountAndQuantity
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof BranchWithAmountAndQuantity
     */
    quantity: number;
}

/**
 * Check if a given object implements the BranchWithAmountAndQuantity interface.
 */
export function instanceOfBranchWithAmountAndQuantity(value: object): value is BranchWithAmountAndQuantity {
    if (!('branch' in value) || value['branch'] === undefined) return false;
    if (!('amount' in value) || value['amount'] === undefined) return false;
    if (!('quantity' in value) || value['quantity'] === undefined) return false;
    return true;
}

export function BranchWithAmountAndQuantityFromJSON(json: any): BranchWithAmountAndQuantity {
    return BranchWithAmountAndQuantityFromJSONTyped(json, false);
}

export function BranchWithAmountAndQuantityFromJSONTyped(json: any, ignoreDiscriminator: boolean): BranchWithAmountAndQuantity {
    if (json == null) {
        return json;
    }
    return {
        
        'branch': BranchFromJSON(json['branch']),
        'amount': json['amount'],
        'quantity': json['quantity'],
    };
}

export function BranchWithAmountAndQuantityToJSON(json: any): BranchWithAmountAndQuantity {
    return BranchWithAmountAndQuantityToJSONTyped(json, false);
}

export function BranchWithAmountAndQuantityToJSONTyped(value?: BranchWithAmountAndQuantity | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'branch': BranchToJSON(value['branch']),
        'amount': value['amount'],
        'quantity': value['quantity'],
    };
}

