import React from "react";
import { SvgIcon } from "@/svg";
import { Loader } from "@/components/Loader";

type FileUploadSectionProps = {
  onFileSelect: (file: File | null) => void;
  isValidating: boolean;
};

export function FileUploadSection({ onFileSelect, isValidating }: FileUploadSectionProps) {
  const [fileName, setFileName] = React.useState<string>("");
  const [isDragging, setIsDragging] = React.useState(false);
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handleUploadClick = () => {
    if (!isValidating) {
      fileInputRef.current?.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    if (file) {
      setFileName(file.name);
    }
    onFileSelect(file);
  };

  const handleDelete = () => {
    setFileName("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    onFileSelect(null);
  };

  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isValidating) {
      setIsDragging(true);
    }
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    if (isValidating) return;

    const file = e.dataTransfer.files?.[0];
    if (file && file.name.endsWith(".xlsx")) {
      setFileName(file.name);
      onFileSelect(file);
    }
  };

  if (isValidating) {
    return (
      <div className="mx-12 border-2 border-dashed border-feintGray/60 rounded-lg py-10 px-2 flex flex-row gap-x-2 items-center justify-center">
        <Loader />
      </div>
    );
  }

  if (fileName) {
    return (
      <div className="flex items-center justify-between px-12">
        <div className="flex items-center gap-x-16">
          <span className="text-feintGray">Uploaded File</span>
          <div className="flex items-center">
            <div className="flex items-center gap-x-2 px-4 py-2">
              <SvgIcon icon="File" />
              <span title={fileName} className="truncate max-w-48 cursor-default">
                {fileName}
              </span>
            </div>
          </div>
        </div>
        <div
          className="flex items-center text-sematicRed gap-x-2 cursor-pointer"
          onClick={handleDelete}
        >
          <SvgIcon icon="Trash" />
          <span>Delete File</span>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`mx-12 border-2 border-dashed ${
        isDragging ? "border-primaryColor bg-primaryColor/5" : "border-feintGray/60"
      } rounded-lg py-10 px-2 flex flex-col gap-y-2 items-center justify-center cursor-pointer transition-colors`}
      onClick={handleUploadClick}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <SvgIcon icon="Upload" />
      <span className="text-primaryColor mt-2">Upload your XLSX</span>
      <input
        type="file"
        ref={fileInputRef}
        className="hidden"
        accept=".xlsx"
        onChange={handleFileChange}
        disabled={isValidating}
      />
    </div>
  );
}
