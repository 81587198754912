export const colors = {
  primaryColor: "#440099",
  secondaryColor: "#7D55C7",
  dark: "#1D232F",
  gray: "#A4ABB8",
  light: "#C0C6CF",
  feintGray: "#777E8B",
  feintWhite: "#C2DFF5",
  neutralContent: "#718096",
  disabled: "#E9E9EC",
  midGray: "#4E5461",
  lightGray: "#F6F8FA",
  offGray: "#F4F4F4",
  errorContent: "#ff0000",
  backgroundGray: "#EAEDF2",
  success: "#06BF7F",
  sematicRed: "#E22B3D",
  sematicGreen: "#E0F8EF",
  sematicYellow: "#FCC838",
  sematicFaintYellow: "rgba(252, 200, 56, 0.4)",
  darkGray: "#363434",
  cyan: "#74d1ea",
  blueishGray: "#EAEFF4",
  whiteBackdrop: "#FFFFFF99",
};
