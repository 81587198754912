/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ThoughtspotLiveboardCategory } from './ThoughtspotLiveboardCategory';
import {
    ThoughtspotLiveboardCategoryFromJSON,
    ThoughtspotLiveboardCategoryFromJSONTyped,
    ThoughtspotLiveboardCategoryToJSON,
    ThoughtspotLiveboardCategoryToJSONTyped,
} from './ThoughtspotLiveboardCategory';

/**
 * 
 * @export
 * @interface ThoughtspotLiveboard
 */
export interface ThoughtspotLiveboard {
    /**
     * 
     * @type {string}
     * @memberof ThoughtspotLiveboard
     */
    liveboard_id: string;
    /**
     * 
     * @type {string}
     * @memberof ThoughtspotLiveboard
     */
    liveboard_name: string;
    /**
     * 
     * @type {boolean}
     * @memberof ThoughtspotLiveboard
     */
    is_favorite: boolean;
    /**
     * 
     * @type {string}
     * @memberof ThoughtspotLiveboard
     */
    icon?: string;
    /**
     * 
     * @type {number}
     * @memberof ThoughtspotLiveboard
     */
    index?: number;
    /**
     * 
     * @type {number}
     * @memberof ThoughtspotLiveboard
     */
    created_at: number;
    /**
     * 
     * @type {ThoughtspotLiveboardCategory}
     * @memberof ThoughtspotLiveboard
     */
    category?: ThoughtspotLiveboardCategory;
}

/**
 * Check if a given object implements the ThoughtspotLiveboard interface.
 */
export function instanceOfThoughtspotLiveboard(value: object): value is ThoughtspotLiveboard {
    if (!('liveboard_id' in value) || value['liveboard_id'] === undefined) return false;
    if (!('liveboard_name' in value) || value['liveboard_name'] === undefined) return false;
    if (!('is_favorite' in value) || value['is_favorite'] === undefined) return false;
    if (!('created_at' in value) || value['created_at'] === undefined) return false;
    return true;
}

export function ThoughtspotLiveboardFromJSON(json: any): ThoughtspotLiveboard {
    return ThoughtspotLiveboardFromJSONTyped(json, false);
}

export function ThoughtspotLiveboardFromJSONTyped(json: any, ignoreDiscriminator: boolean): ThoughtspotLiveboard {
    if (json == null) {
        return json;
    }
    return {
        
        'liveboard_id': json['liveboard_id'],
        'liveboard_name': json['liveboard_name'],
        'is_favorite': json['is_favorite'],
        'icon': json['icon'] == null ? undefined : json['icon'],
        'index': json['index'] == null ? undefined : json['index'],
        'created_at': json['created_at'],
        'category': json['category'] == null ? undefined : ThoughtspotLiveboardCategoryFromJSON(json['category']),
    };
}

export function ThoughtspotLiveboardToJSON(json: any): ThoughtspotLiveboard {
    return ThoughtspotLiveboardToJSONTyped(json, false);
}

export function ThoughtspotLiveboardToJSONTyped(value?: ThoughtspotLiveboard | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'liveboard_id': value['liveboard_id'],
        'liveboard_name': value['liveboard_name'],
        'is_favorite': value['is_favorite'],
        'icon': value['icon'],
        'index': value['index'],
        'created_at': value['created_at'],
        'category': ThoughtspotLiveboardCategoryToJSON(value['category']),
    };
}

