/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LiveboardCategory } from './LiveboardCategory';
import {
    LiveboardCategoryFromJSON,
    LiveboardCategoryFromJSONTyped,
    LiveboardCategoryToJSON,
    LiveboardCategoryToJSONTyped,
} from './LiveboardCategory';
import type { WebAppPrivileges } from './WebAppPrivileges';
import {
    WebAppPrivilegesFromJSON,
    WebAppPrivilegesFromJSONTyped,
    WebAppPrivilegesToJSON,
    WebAppPrivilegesToJSONTyped,
} from './WebAppPrivileges';

/**
 * 
 * @export
 * @interface UserAggregateMetaNorma
 */
export interface UserAggregateMetaNorma {
    /**
     * 
     * @type {string}
     * @memberof UserAggregateMetaNorma
     */
    enterprise_web_mode: UserAggregateMetaNormaEnterpriseWebModeEnum;
    /**
     * 
     * @type {Array<LiveboardCategory>}
     * @memberof UserAggregateMetaNorma
     */
    liveboard_categories_ordered?: Array<LiveboardCategory>;
    /**
     * 
     * @type {string}
     * @memberof UserAggregateMetaNorma
     */
    business_context: string;
    /**
     * 
     * @type {WebAppPrivileges}
     * @memberof UserAggregateMetaNorma
     */
    web_app_privileges: WebAppPrivileges;
}


/**
 * @export
 */
export const UserAggregateMetaNormaEnterpriseWebModeEnum = {
    lite: 'lite',
    pro: 'pro'
} as const;
export type UserAggregateMetaNormaEnterpriseWebModeEnum = typeof UserAggregateMetaNormaEnterpriseWebModeEnum[keyof typeof UserAggregateMetaNormaEnterpriseWebModeEnum];


/**
 * Check if a given object implements the UserAggregateMetaNorma interface.
 */
export function instanceOfUserAggregateMetaNorma(value: object): value is UserAggregateMetaNorma {
    if (!('enterprise_web_mode' in value) || value['enterprise_web_mode'] === undefined) return false;
    if (!('business_context' in value) || value['business_context'] === undefined) return false;
    if (!('web_app_privileges' in value) || value['web_app_privileges'] === undefined) return false;
    return true;
}

export function UserAggregateMetaNormaFromJSON(json: any): UserAggregateMetaNorma {
    return UserAggregateMetaNormaFromJSONTyped(json, false);
}

export function UserAggregateMetaNormaFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAggregateMetaNorma {
    if (json == null) {
        return json;
    }
    return {
        
        'enterprise_web_mode': json['enterprise_web_mode'],
        'liveboard_categories_ordered': json['liveboard_categories_ordered'] == null ? undefined : ((json['liveboard_categories_ordered'] as Array<any>).map(LiveboardCategoryFromJSON)),
        'business_context': json['business_context'],
        'web_app_privileges': WebAppPrivilegesFromJSON(json['web_app_privileges']),
    };
}

export function UserAggregateMetaNormaToJSON(json: any): UserAggregateMetaNorma {
    return UserAggregateMetaNormaToJSONTyped(json, false);
}

export function UserAggregateMetaNormaToJSONTyped(value?: UserAggregateMetaNorma | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'enterprise_web_mode': value['enterprise_web_mode'],
        'liveboard_categories_ordered': value['liveboard_categories_ordered'] == null ? undefined : ((value['liveboard_categories_ordered'] as Array<any>).map(LiveboardCategoryToJSON)),
        'business_context': value['business_context'],
        'web_app_privileges': WebAppPrivilegesToJSON(value['web_app_privileges']),
    };
}

