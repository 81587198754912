export function BILogin() {
  return (
    <svg width="80" height="81" viewBox="0 0 80 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.5" width="80" height="80" rx="10" fill="#7D55C7" />
      <rect width="6" height="24" transform="translate(23 36.5)" fill="white" />
      <rect width="6" height="28" transform="translate(49 32.5)" fill="white" />
      <rect width="6" height="40" transform="translate(36 20.5)" fill="white" />
      <path d="M18 53.5L38.5 40.5L52 47.5L63 39.5" stroke="#777E8B" />
    </svg>
  );
}
