/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BranchWithAmountAndQuantity } from './BranchWithAmountAndQuantity';
import {
    BranchWithAmountAndQuantityFromJSON,
    BranchWithAmountAndQuantityFromJSONTyped,
    BranchWithAmountAndQuantityToJSON,
    BranchWithAmountAndQuantityToJSONTyped,
} from './BranchWithAmountAndQuantity';

/**
 * 
 * @export
 * @interface BusinessReportBranchesWithVoidAmountAndQuantityResponse
 */
export interface BusinessReportBranchesWithVoidAmountAndQuantityResponse {
    /**
     * 
     * @type {Array<BranchWithAmountAndQuantity>}
     * @memberof BusinessReportBranchesWithVoidAmountAndQuantityResponse
     */
    data: Array<BranchWithAmountAndQuantity>;
}

/**
 * Check if a given object implements the BusinessReportBranchesWithVoidAmountAndQuantityResponse interface.
 */
export function instanceOfBusinessReportBranchesWithVoidAmountAndQuantityResponse(value: object): value is BusinessReportBranchesWithVoidAmountAndQuantityResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function BusinessReportBranchesWithVoidAmountAndQuantityResponseFromJSON(json: any): BusinessReportBranchesWithVoidAmountAndQuantityResponse {
    return BusinessReportBranchesWithVoidAmountAndQuantityResponseFromJSONTyped(json, false);
}

export function BusinessReportBranchesWithVoidAmountAndQuantityResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BusinessReportBranchesWithVoidAmountAndQuantityResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(BranchWithAmountAndQuantityFromJSON)),
    };
}

export function BusinessReportBranchesWithVoidAmountAndQuantityResponseToJSON(json: any): BusinessReportBranchesWithVoidAmountAndQuantityResponse {
    return BusinessReportBranchesWithVoidAmountAndQuantityResponseToJSONTyped(json, false);
}

export function BusinessReportBranchesWithVoidAmountAndQuantityResponseToJSONTyped(value?: BusinessReportBranchesWithVoidAmountAndQuantityResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(BranchWithAmountAndQuantityToJSON)),
    };
}

