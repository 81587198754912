import React from "react";
import { isAccountNumberValid } from "@norma-bi/utils";

export const useAccountNumberInput = () => {
  const [accountNumber, setAccountNumber] = React.useState("");

  const handleAccountNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedValue = formatAccountNumber(e.target.value);
    setAccountNumber(formattedValue);
  };

  const formatAccountNumber = (value: string) => {
    return value
      .replace(/[^\d-]/g, "")
      .replace(/(-.*)-+/g, "$1")
      .replace(/^(-)?(\d{1,6})?.*/, "$1$2");
  };

  return {
    accountNumber,
    handleAccountNumberChange,
    isAccountNumberValid: isAccountNumberValid(accountNumber),
  };
};
