import React from "react";
import clsx from "clsx";
import { Navigate, useSearchParams } from "react-router";
import { useForm, SubmitHandler } from "react-hook-form";
import { useResetPassword } from "../../hooks/useResetPassword";
import { jwtDecode } from "jwt-decode";
import * as Sentry from "@sentry/react";
import { PulseLoader } from "react-spinners";
import { BILogin } from "../Login/svg/BILogin";
import { LoginInstructions } from "@/components/LoginInstructions";
import { InfoSquare } from "../Login/svg/InfoSquare";
import { useEmailInput } from "@/hooks/useEmailInput";
import { usePasswordInput } from "@/hooks/usePasswordInput";
import { Eye } from "../Login/svg/Eye";
import { EyeOff } from "../Login/svg/EyeOff";

type ResetPasswordForm = {
  email: string;
  password: string;
  confirmPassword: string;
};

export function ResetPassword() {
  const [params] = useSearchParams();
  const token = params.get("token");
  const resetPassword = useResetPassword();

  const { email, handleEmailChange, isEmailValid } = useEmailInput();
  const { password, showPassword, handlePasswordChange, toggleShowPassword } = usePasswordInput();
  const {
    password: confirmPassword,
    showPassword: showConfirmPassword,
    handlePasswordChange: handleConfirmPasswordChange,
    toggleShowPassword: toggleShowConfirmPassword,
  } = usePasswordInput();

  const { handleSubmit } = useForm<ResetPasswordForm>();

  const hasExpired = React.useMemo(() => {
    if (!token) return false;
    try {
      const decodedToken = jwtDecode(token);
      if (!decodedToken.exp) return false;
      return decodedToken.exp < Date.now() / 1000;
    } catch (e) {
      console.error("failed to decode jwt token: ", e);
      return true;
    }
  }, [token]);

  const onSubmit: SubmitHandler<ResetPasswordForm> = async (data) => {
    if (!token) {
      Sentry.captureEvent({ message: "token is empty when resetting password" });
      return null;
    }
    resetPassword.mutate({ token, newPassword: data.password });
  };

  if (!token) {
    return <Navigate to="/" replace />;
  }

  const canSubmit =
    !resetPassword.isPending &&
    isEmailValid &&
    password !== "" &&
    password.length >= 8 &&
    password === confirmPassword &&
    !hasExpired;

  return (
    <div className="max-w-none mx-auto h-screen flex justify-around items-center bg-lightGray">
      <div className="text-neutralContent">
        <div className="mx-auto w-[80px] h-[80px] mb-10">
          <BILogin />
        </div>
        <form
          className="flex flex-col gap-y-6 text-center text-sm w-[378px]"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="text-xl font-medium text-dark">Reset Your Password</div>
          <div className="text-base text-dark">
            Choose a strong password and make sure to remember it.
          </div>
          {hasExpired && (
            <div className="text-errorContent bg-sematicRed/20 flex items-center gap-2 py-3.5 px-7 rounded-lg text-left">
              <InfoSquare />
              <span>This reset password link has expired. Please request a new one.</span>
            </div>
          )}
          <div className="flex flex-col gap-4 shadow-lg bg-white p-6 rounded-xl">
            <div className="flex flex-col text-left gap-3.5">
              <label className="label text-xs text-dark">Email*</label>
              <input
                type="email"
                className={clsx("thoughtspot-login-input", {
                  error: !isEmailValid && email !== "",
                })}
                placeholder="Enter your Email"
                autoComplete="email"
                value={email}
                onChange={handleEmailChange}
              />
              {!isEmailValid && email !== "" && (
                <span className="text-errorContent text-xs mt-1">Please enter a valid Email</span>
              )}
            </div>

            <div className="flex flex-col text-left gap-3.5">
              <label className="label text-xs text-dark">Password*</label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  className={clsx("thoughtspot-login-input pr-10", {
                    error: password !== "" && password.length < 8,
                  })}
                  placeholder="Enter Password"
                  autoComplete="new-password"
                  value={password}
                  onChange={handlePasswordChange}
                />
                <button
                  type="button"
                  onClick={toggleShowPassword}
                  className="absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer"
                >
                  {showPassword ? <EyeOff /> : <Eye />}
                </button>
              </div>
              {password !== "" && password.length < 8 && (
                <span className="text-errorContent text-xs mt-1">
                  Password must be at least 8 characters long
                </span>
              )}
            </div>
            <div className="flex flex-col text-left gap-3.5">
              <label className="label text-xs text-dark">Password*</label>
              <div className="relative">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  className={clsx("thoughtspot-login-input pr-10", {
                    error: confirmPassword !== "" && password !== confirmPassword,
                  })}
                  placeholder="Repeat Password"
                  autoComplete="new-password"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                />
                <button
                  type="button"
                  onClick={toggleShowConfirmPassword}
                  className="absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer"
                >
                  {showConfirmPassword ? <EyeOff /> : <Eye />}
                </button>
              </div>
              {confirmPassword !== "" && password !== confirmPassword && (
                <span className="text-errorContent text-xs mt-1">Passwords do not match</span>
              )}
            </div>

            <div className="flex pt-6 justify-between">
              <input
                type="submit"
                disabled={!canSubmit}
                className={clsx(
                  "w-full h-9 font-normal rounded-lg text-white cursor-pointer shadow-button bg-primaryColor",
                  canSubmit || "opacity-20",
                )}
                value={resetPassword.isPending ? "Resetting..." : "Reset Password"}
              />
            </div>
          </div>
          {resetPassword.isPending && (
            <div className="flex justify-center pt-4">
              <PulseLoader
                color="lightgray"
                loading={resetPassword.isPending}
                size={15}
                speedMultiplier={0.6}
              />
            </div>
          )}
        </form>
      </div>
      <div className="hidden xl:block">
        <LoginInstructions />
      </div>
    </div>
  );
}
