export function Eye() {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="eye">
        <g id="Icon">
          <path
            d="M1.61342 8.97513C1.52262 8.83137 1.47723 8.75949 1.45182 8.64862C1.43273 8.56534 1.43273 8.43401 1.45182 8.35073C1.47723 8.23986 1.52262 8.16798 1.61341 8.02422C2.36369 6.83624 4.59693 3.83301 8.00027 3.83301C11.4036 3.83301 13.6369 6.83623 14.3871 8.02422C14.4779 8.16798 14.5233 8.23986 14.5487 8.35073C14.5678 8.43401 14.5678 8.56534 14.5487 8.64862C14.5233 8.75949 14.4779 8.83137 14.3871 8.97513C13.6369 10.1631 11.4036 13.1663 8.00027 13.1663C4.59693 13.1663 2.36369 10.1631 1.61342 8.97513Z"
            stroke="#1D232F"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8.00027 10.4997C9.10484 10.4997 10.0003 9.60424 10.0003 8.49967C10.0003 7.39511 9.10484 6.49967 8.00027 6.49967C6.8957 6.49967 6.00027 7.39511 6.00027 8.49967C6.00027 9.60424 6.8957 10.4997 8.00027 10.4997Z"
            stroke="#1D232F"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </g>
    </svg>
  );
}
