import { CustomisationsInterface, CustomCssVariables } from "@thoughtspot/visual-embed-sdk";
import { colors } from "../theme/colors";

export const customizations: CustomisationsInterface = {
  style: {
    customCSS: {
      variables: {
        "--ts-var-root-background": "transparent",
        "--ts-var-root-color": "#111827",
        "--ts-var-viz-box-shadow": "0px 20px 25px -5px rgba(0, 0, 0, 0.04)",
        "--ts-var-viz-border-radius": "1rem",
        "--ts-var-spotter-prompt-background": colors.lightGray,
      } as CustomCssVariables,
      rules_UNSTABLE: {
        // Style the spotter prompt background
        ".spotter-prompt-module__promptBackground": {
          "background-color": colors.lightGray,
        },
        // the original app has a navigator which we are hiding, this margin allows the app to render below our own navigator
        ".bk-embed-powered-by-ts-ribbon.ng-scope.ng-isolate-scope": { display: "none" },
        // this margin gives breathing space in /saved-answer route
        ".answer-module__answer.flex-layout-module__horizontal.spacing-e": {
          "margin-top": "10px !important",
        },
        // hides the footer
        ".footer-module__footerContainer.layout-module__horizontal, .bk-powered-by-ts-ribbon.ng-scope.ng-isolate-scope":
          {
            display: "none !important",
          },
        // the following rules set the primary color for buttons and text around the app
        ".button-module__backgroundLight.button-module__secondary.button-module__buttonSelected, .select-module__roundedHead.select-module__selectOpen .select-module__selectedValue, .segment-control-module__segmentedControl .segment-control-module__segmentedItem.segment-control-module__selected .segment-control-module__innerText, .menu-module__item.menu-module__itemActive>.menu-module__itemInfo>.menu-module__itemText p, .typography-module__body-normal.typography-module__base.facet-sort-bar-module__placeholderFacetSelectValue.select-module__selectedValue.select-module__placeholder.typography-module__whiteSpace.typography-module__noMargin.typography-module__ellipsisText.typography-module__ellipsis, .favorite-button-module__favoriteButtonActive, .link-module__blue:hover, .top-list-filters .bk-filter-item .bk-top-menu-filters li.bk-selected, .top-list-filters .bk-filter-item .bk-top-menu-filters li:hover, .avatar-module__nameLink:hover, .bk-link-button, .menu-module__actionMenu.menu-module__sideNavVariant .menu-module__item>.menu-module__item>.menu-module__itemInfo .menu-module__itemText .menu-module__labelHighlight, .menu-module__actionMenu.menu-module__sideNavVariant .menu-module__menuGroupWrapper>.menu-module__item>.menu-module__itemInfo .menu-module__itemText .menu-module__labelHighlight, .tab-module__tabHorizontal .tab-module__headerItem.tab-module__headerItemSelected .tab-module__headerText, .link-module__blue, .pinboard-tab-module__tabNameContainer .pinboard-tab-module__tabNameEditor.pinboard-tab-module__tabHighlightOnSelected":
          {
            color: colors.primaryColor,
          },
        ".facet-sort-bar-module__facetSelectValue": {
          color: `${colors.primaryColor} !important`,
        },
        "[data-radiant-v2].icon-module__blue, .bk-link-button .svg-icon-info, .radio-module__customRadio .radio-module__customRadioCircle":
          {
            fill: colors.primaryColor,
          },
        ".button-module__backgroundLight.button-module__primary": {
          "background-color": colors.primaryColor,
          color: "#FFFFFF",
        },
        ".button-module__backgroundLight.button-module__primary:not(.button-module__disabled):hover, .rd-button-background-light.rd-button-primary.rd-button-size-m:hover":
          {
            "background-color": colors.secondaryColor,
            color: "#FFFFFF",
          },
        ".button-module__backgroundLight.button-module__primary .button-module__buttonText": {
          color: "#FFFFFF",
        },
        ".toggle-module__toggleCheckbox:checked+.toggle-module__toggleSwitchBackground .toggle-module__toggleSwitchMarker":
          {
            "background-color": colors.primaryColor,
            "border-color": colors.primaryColor,
          },
        ".bk-checkbox-container .bk-checkbox:checked+.bk-checkbox-label::before": {
          "border-color": colors.primaryColor,
        },
        "input[type=checkbox].checkbox-module__nativeCheckbox:indeterminate+.checkbox-module__customCheckbox, input[type=checkbox].checkbox-module__nativeCheckbox:checked+.checkbox-module__customCheckbox":
          {
            "border-color": colors.primaryColor,
            color: colors.primaryColor,
          },
        ".select-module__select .select-module__selectOpen.select-module__selectHead": {
          border: `1px solid ${colors.primaryColor}`,
        },
        ".top-list-filters .bk-filter-item .bk-top-menu-filters li.bk-selected::after, .tab-module__tabHorizontal .tab-module__headerItem:after":
          {
            "background-color": colors.primaryColor,
          },
        ".bk-link-button:hover": {
          color: colors.secondaryColor,
        },
        // sets the colors of KPIs
        ".homepage-carousel-card-module__card .homepage-carousel-card-module__pinboardMetricsCardHeader":
          {
            background: `linear-gradient(91.6deg,${colors.secondaryColor},${colors.primaryColor} 103.93%)`,
          },
        ".rd-button-background-light.rd-button-primary.rd-button-size-m": {
          "background-color": colors.primaryColor,
          color: "#FFFFFF",
        },
        // transforms the edit-liveboard header
        ".pinboard-edit-header-module__editActionHeader": {
          display: "flex",
          "justify-content": "end",
          height: "56px",
          "box-shadow": "none",
          "border-radius": "16px",
        },
        // hidden buttons and logos
        ".pinboard-edit-header-module__editActionHeader .pinboard-edit-header-module__headerLogo, .import-tsl-v2-header-module__tsLogo, .spotiq-box-module__analysisBegun img, .layout-module__sidePanel.layout-module__rightPanel.layout-module__expanded, .button-module__button.button-module__buttonWithIcon.button-module__secondary.button-module__sizeM.button-module__backgroundLight.button-module__both[aria-label='Create alert'][data-testid='monitor-button-action']":
          {
            display: "none",
          },
        // change colors of edit-liveboard header
        ".pinboard-edit-header-module__editActionHeader [data-radiant-v2].icon-module__text-color":
          {
            fill: colors.feintGray,
          },
        ".pinboard-edit-header-module__editActionHeader .button-module__backgroundColored.button-module__tertiary .button-module__buttonText":
          {
            color: colors.feintGray,
          },
        ".pinboard-edit-header-module__editActionHeader.pinboard-edit-header-module__editActionHeaderHeight[data-testid='pinboard-edit-header-container']":
          {
            "background-color": "#FFFFFF !important",
          },
        ".pinboard-edit-header-module__editActionHeader + .pinboard-header-module__pinboardHeaderContainer":
          {
            "margin-top": "80px",
          },
        // removed padding when edit-liveboard shows
        ".pinboard-header-module__headerContainerPrimaryNavHidden": {
          "padding-top": "0px",
        },
        ".button-module__buttonWrapper.toast-alert-module__closeButton .icon-module__iconStyle.icon-module__s.icon-module__text-color":
          { fill: "#FFFFFF" },
        ".eureka-search-bar-module__searchBarWrapper, .eureka-search-bar-module__withoutSage": {
          "background-color": colors.dark,
        },
        ".pinboard-tab-module__tabContainer.pinboard-tab-module__tabSelected": {
          "border-bottom-color": colors.primaryColor,
        },
        ".import-tsl-v2-header-module__header": { padding: "0 0.7rem" },
        ".data-panel-module__header.flex-layout-module__horizontal .button-module__button.button-module__buttonWithIcon.button-module__secondary.button-module__sizeM.button-module__backgroundLight.button-module__both":
          {
            display: "none",
          },
        // stops column author from rerouting you to the author's profile
        ".ag-cell.ag-cell-not-inline-editing.ag-cell-normal-height.ag-cell-focus.ag-cell-value[aria-colindex='5'][col-id='author']":
          {
            "pointer-events": "none",
          },
        ".typography-module__body-normal.typography-module__base.avatar-module__name.avatar-module__nameLink.typography-module__whiteSpace":
          {
            cursor: "default",
            "text-decoration": "none",
          },
        ".typography-module__body-normal.typography-module__base.avatar-module__name.avatar-module__nameLink.typography-module__whiteSpace:hover":
          {
            cursor: "default",
            "text-decoration": "none",
          },
        ".ag-header-container .ag-header-cell": {
          "border-bottom": "0px !important",
        },
        // hides columns
        ".ag-header-cell[col-id='views'], .ag-cell[col-id='views'], .ag-header-cell[col-id='isVerified'], .ag-cell[col-id='isVerified']":
          {
            display: "none",
          },
        ".ag-header-row.ag-header-row-column": {
          "border-bottom": "1px solid #eaedf2",
        },
        "div[data-testid='liveboard-users'].pinboard-header-module__avatarDiv": {
          display: "none",
        },
        ".stickers.ng-scope .svg-icon-block.svg-icon-info-block.svg-icon-block-m.rd-icon-tag-m, .stickers.ng-scope .bk-title.ng-binding, .stickers.ng-scope .sticker-selector-container, rd-button[ng-if='::$ctrl.metadataListSecondaryButtonControl'] .rd-button-wrapper":
          {
            display: "none",
          },
        ".bk-secondary-actions .bk-action-item:nth-of-type(2), .bk-secondary-actions .bk-action-item:nth-of-type(3), .bk-secondary-actions .bk-action-item:nth-of-type(4), .wizard-module__wizardHeader":
          {
            display: "none",
          },
        ".ReactModal__Content.ReactModal__Content--after-open.modal-module__modal.modal-module__fullscreen":
          {
            "margin-top": "85px",
          },
        // hide request access for liveboards
        ".request-access-module__requestAccessBtn": {
          display: "none",
        },
        // hide AI highlights
        ".pinboard-header-module__pinboardActionContainer #highlights": {
          display: "none",
        },
        // hide SpotIQ default preferences
        ".spotiq-actionbar-module__defaultActionBar .button-module__button.button-module__buttonWithIcon.button-module__buttonWithText.button-module__secondary.button-module__sizeM.button-module__backgroundLight.button-module__both":
          {
            display: "none",
          },

        // hide Modify schedule from SpotIQ
        "#actionmenu_rd-val-default #schedule-job": {
          display: "none",
        },
        // hide SpotIQ dropdown options
        ".popover-module__positionable.popover-module__in #Share, .popover-module__positionable.popover-module__in #schedule_jobs, .popover-module__positionable.popover-module__in #analysis_details":
          {
            display: "none",
          },
        // hide action (lightning) button of search data and answers
        "[data-testid=action-selector-nav-btn]": {
          display: "none",
        },
        // hide KPI actions
        ".popover-module__positionable.popover-module__in #embedDocument, .popover-module__positionable.popover-module__in #manageMonitor":
          {
            display: "none",
          },
        // hides actions that we couldn't hide through TS Actions
        "#actionmenu_rd-val-default #tml": {
          display: "none !important",
        },
        "#actionmenu_rd-val-default .divider-module__includeSectionBorder:after": {
          display: "none !important",
        },
        // tightens the space between navbar and container in SpotIQ
        "[data-testid=spotiq-list-container] .metadata-list-module__container": {
          "padding-top": "0px",
        },
        ".button-module__buttonWrapper.pinboard-header-module__shareButton [data-testid=share-button]":
          {
            "background-color": colors.primaryColor,
            color: "#FFFFFF",
          },
        ".button-module__buttonWrapper.pinboard-header-module__shareButton:hover [data-testid=share-button]":
          {
            "background-color": colors.secondaryColor,
          },
        // hides and stylizes lite layout scroll
        ".pinboard-module__pinboardPage::-webkit-scrollbar": {
          width: "5px",
          "background-color": "transparent",
        },
        ".pinboard-module__pinboardPage::-webkit-scrollbar-track": {
          background: "transparent",
          "border-radius": "10px",
        },
        ".pinboard-module__pinboardPage::-webkit-scrollbar-thumb": {
          background: "transparent",
          "border-radius": "10px",
          transition: "background-color 0.3s ease",
        },
        ".pinboard-module__pinboardPage:hover::-webkit-scrollbar-track": {
          background: "transparent",
        },
        ".pinboard-module__pinboardPage:hover::-webkit-scrollbar-thumb": {
          background: "#888",
        },
        ".pinboard-module__pinboardPage:hover::-webkit-scrollbar-thumb:hover": {
          background: "#555",
        },
        // hides view availability box
        ".rdLoaderGlobalWrap.rdHideLoader.modal-module__loadingWrapper #isPublic": {
          display: "none",
        },
        // hides the header cell for the "Anyone can view" column
        "[data-testid='manage-personalised-views'] .ag-root-wrapper .ag-header-cell[aria-colindex='3']":
          {
            display: "none !important",
          },
        // hides the cell content for the "Anyone can view" column
        "[data-testid='manage-personalised-views'] .ag-root-wrapper .ag-cell[aria-colindex='3']": {
          display: "none !important",
        },
        // hides data source in visualization edit
        ".viz-context-answer-module__wrapper .viz-context-answer-module__bkContextDialogContainer .viz-context-answer-module__content .sage-search-bar-with-error-bar-module__sageDataSourcePreviewSection":
          {
            "min-width": "0px",
          },
        ".viz-context-answer-module__wrapper .viz-context-answer-module__bkContextDialogContainer .viz-context-answer-module__content .sage-data-source-preview-module__sageDataSourcePreviewContainer.layout-module__horizontal":
          {
            display: "none !important",
          },
        // hides data source in search data
        ".sage-search-bar-with-error-bar-module__sageDataSourcePreviewSection": {
          "min-width": "0px",
        },
        ".sage-data-source-preview-module__sageDataSourcePreviewContainer.layout-module__horizontal":
          {
            display: "none !important",
          },
        // remove outside spotter scrollbar
        ".authenticated-app-view-module__pageContent.authenticated-app-view-module__force-overflow-auto":
          { overflow: "visible !important" },
        // changes search backdrop color
        ".answer-module__searchCurtain": {
          "background-color": colors.lightGray,
        },
        // hiding search data button
        ".muted-alert-module__actionButtons.muted-alert-module__m": {
          display: "none",
        },
        // changing edit liveboard header size
        ".pinboard-edit-header-module__editActionHeader.pinboard-edit-header-module__editActionHeaderHeight":
          {
            right: "0",
            left: "0",
          },
        ".pinboard-header-module__pinboardHeaderContainer.pinboard-header-module__embedPinboardHeader.layout-module__vertical":
          {
            "margin-left": "1.8rem",
            "margin-right": "1.8rem",
          },
        // hide visualization edit data panel
        ".viz-context-answer-module__content .answer-module__answer .answer-module__leftPanel": {
          display: "none",
        },
        // visualization edit header fixes
        ".viz-context-answer-module__popupHeader.flex-layout-module__horizontal": {
          "border-radius": "10px",
        },
        ".viz-context-answer-module__bkContent .viz-context-answer-module__content .answer-module__answer":
          {
            padding: "1.7rem",
          },
        ".viz-context-answer-module__bkContextDialogContainer .viz-context-answer-module__bkContextDialog .viz-context-answer-module__popupHeader":
          {
            "margin-left": "1.7rem",
            "margin-right": "1.7rem",
          },
        ".viz-context-answer-module__bkContextDialog": {
          "background-color": colors.lightGray,
        },
        // Scrollbar changes to fit our own
        ".data-sources-v2-module__mainAccordionContainer.data-sources-v2-module__marginForOuterContainer::-webkit-scrollbar, .authenticated-app-view-module__blink.authenticated-app-view-module__blinkInEmbedWithFooter.flex-layout-module__vertical::-webkit-scrollbar, [data-testid='schedule-modal-content']::-webkit-scrollbar, .universal-share-dialog-share-list-module__shareItemListContainer.flex-layout-module__vertical::-webkit-scrollbar, .modal-module__content.modal-module__autoScrollContent.modal-module__noPadding::-webkit-scrollbar, .rdLoaderGlobalWrap.rdHideLoader.modal-module__loadingWrapper .modal-module__content::-webkit-scrollbar":
          {
            width: "5px",
            "background-color": "transparent",
          },
        ".data-sources-v2-module__mainAccordionContainer.data-sources-v2-module__marginForOuterContainer::-webkit-scrollbar-track, .authenticated-app-view-module__blink.authenticated-app-view-module__blinkInEmbedWithFooter.flex-layout-module__vertical::-webkit-scrollbar-track, [data-testid='schedule-modal-content']::-webkit-scrollbar-track, .universal-share-dialog-share-list-module__shareItemListContainer.flex-layout-module__vertical::-webkit-scrollbar-track, .modal-module__content.modal-module__autoScrollContent.modal-module__noPadding::-webkit-scrollbar-track, .rdLoaderGlobalWrap.rdHideLoader.modal-module__loadingWrapper .modal-module__content::-webkit-scrollbar-track":
          {
            background: "transparent",
            "border-radius": "10px",
          },
        ".data-sources-v2-module__mainAccordionContainer.data-sources-v2-module__marginForOuterContainer::-webkit-scrollbar-thumb, .authenticated-app-view-module__blink.authenticated-app-view-module__blinkInEmbedWithFooter.flex-layout-module__vertical::-webkit-scrollbar-thumb, [data-testid='schedule-modal-content']::-webkit-scrollbar-thumb, .universal-share-dialog-share-list-module__shareItemListContainer.flex-layout-module__vertical::-webkit-scrollbar-thumb, .modal-module__content.modal-module__autoScrollContent.modal-module__noPadding::-webkit-scrollbar-thumb, .rdLoaderGlobalWrap.rdHideLoader.modal-module__loadingWrapper .modal-module__content::-webkit-scrollbar-thumb":
          {
            background: "transparent",
            "border-radius": "10px",
            transition: "background-color 0.3s ease",
          },
        ".data-sources-v2-module__mainAccordionContainer.data-sources-v2-module__marginForOuterContainer:hover::-webkit-scrollbar-track, .authenticated-app-view-module__blink.authenticated-app-view-module__blinkInEmbedWithFooter.flex-layout-module__vertical:hover::-webkit-scrollbar-track, [data-testid='schedule-modal-content']:hover::-webkit-scrollbar-track, .universal-share-dialog-share-list-module__shareItemListContainer.flex-layout-module__vertical:hover::-webkit-scrollbar-track, .modal-module__content.modal-module__autoScrollContent.modal-module__noPadding:hover::-webkit-scrollbar-track, .rdLoaderGlobalWrap.rdHideLoader.modal-module__loadingWrapper .modal-module__content:hover::-webkit-scrollbar-track":
          {
            background: "transparent",
          },
        ".data-sources-v2-module__mainAccordionContainer.data-sources-v2-module__marginForOuterContainer:hover::-webkit-scrollbar-thumb, .authenticated-app-view-module__blink.authenticated-app-view-module__blinkInEmbedWithFooter.flex-layout-module__vertical:hover::-webkit-scrollbar-thumb, [data-testid='schedule-modal-content']:hover::-webkit-scrollbar-thumb, .universal-share-dialog-share-list-module__shareItemListContainer.flex-layout-module__vertical:hover::-webkit-scrollbar-thumb, .modal-module__content.modal-module__autoScrollContent.modal-module__noPadding:hover::-webkit-scrollbar-thumb, .rdLoaderGlobalWrap.rdHideLoader.modal-module__loadingWrapper .modal-module__content:hover::-webkit-scrollbar-thumb":
          {
            background: "#888",
          },
        ".data-sources-v2-module__mainAccordionContainer.data-sources-v2-module__marginForOuterContainer:hover::-webkit-scrollbar-thumb:hover, .authenticated-app-view-module__blink.authenticated-app-view-module__blinkInEmbedWithFooter.flex-layout-module__vertical:hover::-webkit-scrollbar-thumb:hover, [data-testid='schedule-modal-content']:hover::-webkit-scrollbar-thumb:hover, .universal-share-dialog-share-list-module__shareItemListContainer.flex-layout-module__vertical:hover::-webkit-scrollbar-thumb:hover, .modal-module__content.modal-module__autoScrollContent.modal-module__noPadding:hover::-webkit-scrollbar-thumb:hover, .rdLoaderGlobalWrap.rdHideLoader.modal-module__loadingWrapper .modal-module__content:hover::-webkit-scrollbar-thumb:hover":
          {
            background: "#555",
          },
        // moved sage search to match rest of app
        ".sage-search-bar-with-error-bar-module__sageSearchBarWithErrorBarContainerWithDataPanelV2.flex-layout-module__vertical":
          {
            "margin-left": "3.5rem",
            "margin-right": "2rem",
          },
        ".answer-module__answer.answer-module__answerWithDataPanelV2.flex-layout-module__horizontal":
          {
            "margin-left": "2rem",
          },
      },
    },
  },
};
