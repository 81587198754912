import { create } from "zustand";
import { persist } from "zustand/middleware";

type SidebarState = {
  expandedCategories: Record<string, boolean>;
  lastSelectedLiveboard: string | null;
  setExpandedCategory: (categoryId: string, expanded: boolean) => void;
  setLastSelectedLiveboard: (liveboardId: string | null) => void;
};

export const useSidebarStore = create<SidebarState>()(
  persist(
    (set) => ({
      expandedCategories: {},
      lastSelectedLiveboard: null,
      setExpandedCategory: (categoryId, expanded) =>
        set((state) => ({
          expandedCategories: {
            ...state.expandedCategories,
            [categoryId]: expanded,
          },
        })),
      setLastSelectedLiveboard: (liveboardId) =>
        set(() => ({
          lastSelectedLiveboard: liveboardId,
        })),
    }),
    {
      name: "sidebar-storage",
      partialize: (state) => ({
        expandedCategories: state.expandedCategories,
        lastSelectedLiveboard: state.lastSelectedLiveboard,
      }),
    },
  ),
);
