/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebAppPrivileges
 */
export interface WebAppPrivileges {
    /**
     * 
     * @type {boolean}
     * @memberof WebAppPrivileges
     */
    spotter: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WebAppPrivileges
     */
    search_data: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WebAppPrivileges
     */
    drill_down: boolean;
}

/**
 * Check if a given object implements the WebAppPrivileges interface.
 */
export function instanceOfWebAppPrivileges(value: object): value is WebAppPrivileges {
    if (!('spotter' in value) || value['spotter'] === undefined) return false;
    if (!('search_data' in value) || value['search_data'] === undefined) return false;
    if (!('drill_down' in value) || value['drill_down'] === undefined) return false;
    return true;
}

export function WebAppPrivilegesFromJSON(json: any): WebAppPrivileges {
    return WebAppPrivilegesFromJSONTyped(json, false);
}

export function WebAppPrivilegesFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebAppPrivileges {
    if (json == null) {
        return json;
    }
    return {
        
        'spotter': json['spotter'],
        'search_data': json['search_data'],
        'drill_down': json['drill_down'],
    };
}

export function WebAppPrivilegesToJSON(json: any): WebAppPrivileges {
    return WebAppPrivilegesToJSONTyped(json, false);
}

export function WebAppPrivilegesToJSONTyped(value?: WebAppPrivileges | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'spotter': value['spotter'],
        'search_data': value['search_data'],
        'drill_down': value['drill_down'],
    };
}

