import React from "react";
import { isEmailValid } from "@norma-bi/utils";

export const useEmailInput = () => {
  const [email, setEmail] = React.useState("");

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  return {
    email,
    handleEmailChange,
    isEmailValid: isEmailValid(email),
  };
};
