export function InfoSquare() {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="info-square">
        <path
          id="Icon"
          d="M12.5 16.5V12.5M12.5 8.5H12.51M8.3 21.5H16.7C18.3802 21.5 19.2202 21.5 19.862 21.173C20.4265 20.8854 20.8854 20.4265 21.173 19.862C21.5 19.2202 21.5 18.3802 21.5 16.7V8.3C21.5 6.61984 21.5 5.77976 21.173 5.13803C20.8854 4.57354 20.4265 4.1146 19.862 3.82698C19.2202 3.5 18.3802 3.5 16.7 3.5H8.3C6.61984 3.5 5.77976 3.5 5.13803 3.82698C4.57354 4.1146 4.1146 4.57354 3.82698 5.13803C3.5 5.77976 3.5 6.61984 3.5 8.3V16.7C3.5 18.3802 3.5 19.2202 3.82698 19.862C4.1146 20.4265 4.57354 20.8854 5.13803 21.173C5.77976 21.5 6.61984 21.5 8.3 21.5Z"
          stroke="#E22B3D"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
}
