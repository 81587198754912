import { LoginThinking } from "./LoginThinking";
import { GETTING_STARTED_DOCS_URL } from "@/constants";

export function LoginInstructions() {
  return (
    <div className="flex flex-col rounded-3xl login-gradient pt-14 pl-10 gap-y-11">
      <div className="flex flex-col gap-y-6">
        <div className="flex flex-col gap-y-4">
          <div className="text-3xl font-medium">How to Login?</div>
          <div className="text-feintGray max-w-[450px]">
            To access Foodics BI, separate credentials from the console are required. Please click
            the button below to proceed.
          </div>
        </div>
        <a
          href={GETTING_STARTED_DOCS_URL}
          target="_blank"
          rel="noopener noreferrer"
          className="border border-light w-52 rounded-lg px-4 py-2 text-center text-feintGray cursor-pointer hover:bg-opacity-10 hover:bg-white"
        >
          Getting Started
        </a>
      </div>
      <LoginThinking />
    </div>
  );
}
