import React from "react";
import clsx from "clsx";
import { useForm, SubmitHandler } from "react-hook-form";
import { useLogin } from "@/hooks/useLogin";
import { Link, Outlet, useLocation, useNavigate, useSearchParams } from "react-router";
import { initiateNewFoodicsBusinessInstallation } from "@/api";
import { LoginRequest as LoginDetails } from "@norma-bi/bi-api";
import { PulseLoader } from "react-spinners";
import { BILogin } from "./svg/BILogin";
import { Eye } from "./svg/Eye";
import { EyeOff } from "./svg/EyeOff";
import { InfoSquare } from "./svg/InfoSquare";
import { LoginInstructions } from "@/components/LoginInstructions";
import { useAccountNumberInput } from "@/hooks/useAccountNumberInput";
import { useEmailInput } from "@/hooks/useEmailInput";
import { usePasswordInput } from "@/hooks/usePasswordInput";

export function Login() {
  const { login, isPending, isWrongCredentials } = useLogin();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [loginAttempts, setLoginAttempts] = React.useState(0);

  const isAlreadyInstalled = searchParams.has("already-installed");

  type LoginForm = Omit<LoginDetails, "businessRef"> & { accountNumber: string };

  const { handleSubmit, register } = useForm<LoginForm>();

  const { accountNumber, handleAccountNumberChange, isAccountNumberValid } =
    useAccountNumberInput();
  const { email, handleEmailChange, isEmailValid } = useEmailInput();
  const { password, showPassword, handlePasswordChange, toggleShowPassword } = usePasswordInput();

  const onSubmit: SubmitHandler<LoginForm> = async () => {
    const result = await login({
      business_ref: parseInt(accountNumber),
      email: email,
      password: password,
    });
    if (!result) {
      setLoginAttempts((prev) => prev + 1);
      return;
    }
    const redirectAfterLogin: Location = location.state?.redirectAfterLogin;
    if (redirectAfterLogin) {
      navigate(redirectAfterLogin, { replace: true });
    } else {
      navigate("/", { replace: true });
    }
  };

  const canSignIn = !isPending && isAccountNumberValid && isEmailValid && password !== "";

  return (
    <>
      <Outlet />
      <div className="max-w-none mx-auto h-screen flex justify-around items-center bg-lightGray">
        <div className="text-neutralContent">
          <div className="mx-auto w-[80px] h-[80px] mb-10">
            <BILogin />
          </div>
          <form
            className="space-y-10 text-center text-sm w-[378px]"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="text-xl font-medium text-dark">Log in to your Foodics BI account</div>
            <div>
              {(isWrongCredentials || isAlreadyInstalled) && (
                <div className="text-errorContent bg-sematicRed/20 flex items-center gap-2 py-3.5 px-7 rounded-lg text-left">
                  <div className="flex-shrink-0">
                    <InfoSquare />
                  </div>
                  <div className="flex-1">
                    {isAlreadyInstalled ? (
                      <>
                        You cannot install Foodics BI more than once. If you're having trouble
                        accessing your account, please try resetting your password.
                        {location.state?.adminEmails?.length > 0 && (
                          <>
                            <br />
                            The registered email addresses are:
                            <ul className="list-disc ml-4 mt-1">
                              {location.state.adminEmails.map((email: string) => (
                                <li key={email}>{email}</li>
                              ))}
                            </ul>
                          </>
                        )}
                      </>
                    ) : loginAttempts <= 1 ? (
                      "These credentials do not match our records"
                    ) : (
                      <>
                        Make sure your Business has Foodics BI{" "}
                        <button
                          onClick={initiateNewFoodicsBusinessInstallation}
                          className="underline hover:opacity-80"
                        >
                          Installed
                        </button>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="flex flex-col gap-4 shadow-lg bg-white p-6 rounded-xl">
              <div className="flex flex-col text-left gap-3.5">
                <label className="label text-xs text-dark">Business Reference (F5 Number)*</label>
                <input
                  type="text"
                  maxLength={7}
                  inputMode="numeric"
                  className={clsx("thoughtspot-login-input", {
                    error: !isAccountNumberValid && accountNumber !== "",
                  })}
                  placeholder="Enter 6 Digit Number"
                  value={accountNumber}
                  {...register("accountNumber", {
                    onChange: (e) => handleAccountNumberChange(e),
                  })}
                />
                {!isAccountNumberValid && accountNumber !== "" && (
                  <span className="text-errorContent text-xs mt-1">
                    Please enter a 6-digit number
                  </span>
                )}
              </div>
              <div className="flex flex-col text-left gap-3.5">
                <label className="label text-xs text-dark">Email*</label>
                <input
                  type="email"
                  className={clsx("thoughtspot-login-input", {
                    error: !isEmailValid && email !== "",
                  })}
                  placeholder="Enter your Email"
                  autoComplete="email"
                  value={email}
                  {...register("email", {
                    onChange: (e) => handleEmailChange(e),
                  })}
                />
                {!isEmailValid && email !== "" && (
                  <span className="text-errorContent text-xs mt-1">Please enter a valid Email</span>
                )}
              </div>
              <div className="flex flex-col text-left gap-3.5">
                <label className="label text-xs text-dark">Password*</label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="thoughtspot-login-input pr-10"
                    placeholder="Enter your Password"
                    id="current-password"
                    autoComplete="current-password"
                    value={password}
                    {...register("password", {
                      onChange: (e) => handlePasswordChange(e),
                    })}
                  />
                  <button
                    type="button"
                    onClick={toggleShowPassword}
                    className="absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer"
                  >
                    {showPassword ? <EyeOff /> : <Eye />}
                  </button>
                </div>
              </div>
              <input
                type="submit"
                disabled={!canSignIn}
                className={clsx(
                  "w-full h-9 font-normal rounded-lg text-white cursor-pointer shadow-button bg-primaryColor",
                  canSignIn || "opacity-20",
                )}
                value={isPending ? "Logging in..." : "Login"}
              />

              <div className="px-1 w-full">
                <div className="flex justify-center">
                  <Link to="/forgot-password" className="label text-sm text-dark hover:underline">
                    Forgot your Password?
                  </Link>
                </div>
              </div>
            </div>
            {isPending && (
              <div className="flex justify-center pt-4">
                <PulseLoader
                  color="lightgray"
                  loading={isPending}
                  size={15}
                  speedMultiplier={0.6}
                />
              </div>
            )}
            <div className="flex justify-center">
              <button type="button" onClick={initiateNewFoodicsBusinessInstallation}>
                <span className="text-sm text-dark hover:underline">
                  Unregistered? Install your business by pressing here!
                </span>
              </button>
            </div>
          </form>
        </div>
        <div className="hidden xl:block">
          <LoginInstructions />
        </div>
      </div>
    </>
  );
}
