import React from "react";
import clsx from "clsx";
import { useForm, SubmitHandler } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { useAPI } from "@/api";
import { PulseLoader } from "react-spinners";
import { BILogin } from "../Login/svg/BILogin";
import { InfoSquare } from "../Login/svg/InfoSquare";
import { LoginInstructions } from "@/components/LoginInstructions";
import { RequestResetPasswordResponseResultEnum } from "@norma-bi/bi-api";
import { Link } from "react-router";
import { initiateNewFoodicsBusinessInstallation } from "@/api";
import { BigCheck } from "../Login/svg/BigCheck";
import { useAccountNumberInput } from "@/hooks/useAccountNumberInput";
import { useEmailInput } from "@/hooks/useEmailInput";

type ForgotPasswordForm = {
  accountNumber: string;
  email: string;
};

type ErrorType = "business-not-found" | "user-not-found" | "user-has-no-branches" | null;

export function ForgotPassword() {
  const api = useAPI();
  const [isSubmitSuccess, setIsSubmitSuccess] = React.useState(false);
  const [error, setError] = React.useState<ErrorType>(null);

  const { handleSubmit } = useForm<ForgotPasswordForm>();

  const { accountNumber, handleAccountNumberChange, isAccountNumberValid } =
    useAccountNumberInput();
  const { email, handleEmailChange, isEmailValid } = useEmailInput();

  const resetPassword = useMutation({
    mutationFn: () => {
      return api.postRequestResetPassword({
        RequestResetPasswordRequest: {
          business_ref: parseInt(accountNumber),
          email: email,
        },
      });
    },
  });

  const [resetPasswordResult, setResetPasswordResult] =
    React.useState<RequestResetPasswordResponseResultEnum | null>(null);

  const onSubmit: SubmitHandler<ForgotPasswordForm> = async () => {
    const { result } = await resetPassword.mutateAsync();
    handleResetPasswordResult(result);
  };

  const handleResetPasswordResult = (result: RequestResetPasswordResponseResultEnum) => {
    if (result === "business-not-found") {
      setError("business-not-found");
      return;
    }
    if (result === "user-not-found-in-business") {
      setError("user-not-found");
      return;
    }
    if (result === "user-has-no-branches") {
      setError("user-has-no-branches");
      return;
    }
    setResetPasswordResult(result);
    setIsSubmitSuccess(true);
    setError(null);
  };

  const canSubmit = !resetPassword.isPending && isAccountNumberValid && isEmailValid;

  return (
    <div className="max-w-none mx-auto h-screen flex justify-around items-center bg-lightGray">
      <div className="text-neutralContent">
        <div className="mx-auto w-[80px] h-[80px] mb-10">
          <BILogin />
        </div>
        <form
          className="flex flex-col text-center text-sm w-[378px] gap-y-6"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="text-xl font-medium text-dark">Forgot your Password?</div>
          <div className="text-base text-dark">
            Please provide your registered email address to receive a one-time secure link to reset
            your password.
          </div>
          {error === "business-not-found" && (
            <div className="text-errorContent bg-sematicRed/20 flex items-center gap-6 py-3.5 px-7 rounded-lg text-left">
              <div className="w-5 h-5 flex-shrink-0">
                <InfoSquare />
              </div>
              <span>
                The business hasn't installed Foodics BI yet, please press the{" "}
                <button
                  onClick={initiateNewFoodicsBusinessInstallation}
                  className="underline hover:opacity-80"
                >
                  Install
                </button>{" "}
                button and
                <br />
                enter the Owner's Credentials
              </span>
            </div>
          )}
          {error === "user-not-found" && (
            <div className="text-errorContent bg-sematicRed/20 flex items-center gap-7 py-3.5 px-7 rounded-lg text-left">
              <div className="w-5 h-5 flex-shrink-0">
                <InfoSquare />
              </div>
              <span>
                The email doesn't belong to the business you typed, please add a user with this
                email in the console or try again with another email
              </span>
            </div>
          )}
          {error === "user-has-no-branches" && (
            <div className="text-errorContent bg-sematicRed/20 flex items-center gap-7 py-3.5 px-7 rounded-lg text-left">
              <div className="w-5 h-5 flex-shrink-0">
                <InfoSquare />
              </div>
              <span>
                This user has 0 branches in the console. Add branches to this user and try again.
              </span>
            </div>
          )}
          <div className="flex flex-col gap-4 justify-center items-center shadow-lg bg-white p-6 rounded-xl">
            {isSubmitSuccess &&
            (resetPasswordResult === "onboarded-jit" || resetPasswordResult === "ok") ? (
              <div className="flex flex-col justify-center items-center gap-4">
                <div className="flex flex-col w-14 h-14 rounded-full justify-center items-center bg-[#E0F8EF]">
                  <div className="text-white bg-success w-10 h-10 rounded-full flex items-center justify-center">
                    <BigCheck />
                  </div>
                </div>
                <div className="flex flex-col justify-center items-center text-dark gap-8">
                  <div className="text-lg font-medium">Password Reset Sent</div>
                  <div className="text-sm font-medium">
                    Check your email, a link to activate or reset your account has been sent
                  </div>
                  <div className="text-sm font-medium text-feintGray">
                    If you're not receiving an email, check your spam or make sure you typed the
                    email correctly
                  </div>
                  <Link to="/login" className="label text-sm text-dark hover:underline">
                    Back to Login
                  </Link>
                </div>
              </div>
            ) : (
              <>
                <div className="flex flex-col text-left gap-3.5 w-80">
                  <label className="label text-xs text-dark">Business Reference (F5 Number)*</label>
                  <input
                    type="text"
                    maxLength={7}
                    pattern="-?[0-9]{1,6}"
                    inputMode="numeric"
                    className="thoughtspot-login-input"
                    placeholder="Enter 6 Digit Number"
                    value={accountNumber}
                    onChange={handleAccountNumberChange}
                  />
                </div>
                <div className="flex flex-col text-left gap-3.5 w-80">
                  <label className="label text-xs text-dark">Email*</label>
                  <input
                    type="email"
                    className={clsx("thoughtspot-login-input", {
                      error: !isEmailValid && email !== "",
                    })}
                    placeholder="Enter your Email"
                    autoComplete="email"
                    value={email}
                    onChange={handleEmailChange}
                  />
                  {!isEmailValid && email !== "" && (
                    <span className="text-errorContent text-xs mt-1">
                      Please enter a valid Email
                    </span>
                  )}
                </div>
                <div className="flex pt-6 w-80 justify-between">
                  <input
                    type="submit"
                    disabled={!canSubmit}
                    className={clsx(
                      "w-full h-9 font-normal rounded-lg text-white cursor-pointer shadow-button bg-primaryColor",
                      canSubmit || "opacity-20",
                    )}
                    value={resetPassword.isPending ? "Submitting..." : "Submit"}
                  />
                </div>
                <div className="px-1 w-full">
                  <div className="flex justify-center">
                    <Link to="/login" className="label text-sm text-dark hover:underline">
                      Back to Login
                    </Link>
                  </div>
                </div>
              </>
            )}
          </div>
          {resetPassword.isPending && (
            <div className="flex justify-center pt-4">
              <PulseLoader
                color="lightgray"
                loading={resetPassword.isPending}
                size={15}
                speedMultiplier={0.6}
              />
            </div>
          )}
        </form>
      </div>
      <div className="hidden xl:block">
        <LoginInstructions />
      </div>
    </div>
  );
}
